export type BaseResponse<T> = {
  data: T;
  message: string;
  info: {
    timestamp: string;
    path: string;
  };
};

export type PaginatedBaseResponseObject<T> = {
  totalElements: number;
  numberOfElements: number;
  number: number;
  content: T[];
  totalPages: number;
  currentPage: number;
  pageSize: number;
  size: number;
};

export const USER_DB_KEY = 'dXNlcg==';
