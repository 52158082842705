import { concatMap, Observable, withLatestFrom } from 'rxjs';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthStore } from '@auth/data-access/store/auth.store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly authStore = inject(AuthStore);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authStore.token$.pipe(
      withLatestFrom(this.authStore.idToken$),
      concatMap(([token, idToken]) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'id-token': idToken?.__raw || '',
          },
          withCredentials: true,
        });
        return next.handle(request);
      })
    );
  }
}
