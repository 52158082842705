import { concatMap, Observable, of } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthStore } from '@auth/data-access/store/auth.store';

import { AppRoutes } from '../routes';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard implements CanActivate {
  readonly authStore = inject(AuthStore);

  readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authStore.userPermissions$.pipe(
      concatMap((userPermissions) => {
        if (!(route && route.data)) return of(false);
        const routePermission = route.data['authorize'] ?? null;
        if (userPermissions.includes(routePermission)) return of(true);
        this.router.navigate(['/', AppRoutes.UNAUTHORIZED]);
        return of(false);
      })
    );
  }
}
