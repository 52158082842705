import jwtDecode from 'jwt-decode';
import {
  catchError,
  EMPTY,
  of,
  switchMap,
  tap,
  concatMap,
  withLatestFrom,
  forkJoin,
  startWith,
  combineLatest,
} from 'rxjs';
import { isEmpty } from 'underscore';

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { ComponentStore } from '@ngrx/component-store';
import { AppRoutes } from '@shared/utils/routes';

import { AuthState, INITIAL_AUTH_STATE } from '../models/auth.state';
import { AuthService } from '../services/auth.service';
import { USER_DB_KEY } from '@shared/utils/api-interfaces';

type AuthJwt<T> = {
  [key in keyof T]: T[key];
};

@Injectable({
  providedIn: 'root',
})
export class AuthStore extends ComponentStore<AuthState> {
  private readonly auth0Service = inject(Auth0Service);

  private readonly authService = inject(AuthService);

  private readonly router = inject(Router);

  loading$ = this.select((state) => state.loading);

  currentUser$ = this.auth0Service.user$;

  isAuthenticated$ = this.auth0Service.isAuthenticated$;

  token$ = this.auth0Service.getAccessTokenSilently();

  idToken$ = this.auth0Service.getIdTokenClaims();

  constructor() {
    super(INITIAL_AUTH_STATE);
  }

  readonly userPermissions$ = this.select(this.token$, (token) => {
    if (token) {
      const decodedAccessToken: AuthJwt<{ permissions: string[] }> =
        jwtDecode(token);
      const userPermissions: Array<string> = decodedAccessToken.permissions;
      return userPermissions;
    } else {
      return [];
    }
  });

  // effects
  readonly login = this.effect(($) =>
    $.pipe(
      switchMap(() =>
        this.auth0Service.loginWithRedirect({
          appState: { target: `${AppRoutes.AUTH}/${AppRoutes.AUTH_CALLBACK}` },
        })
      ),
      catchError((err: unknown) => {
        console.error('Error in login effect', err);
        return EMPTY;
      })
    )
  );

  readonly logout = this.effect(($) =>
    $.pipe(
      tap(() => {
        this.auth0Service.logout({
          returnTo: window.location.origin + '/auth/login',
        });
      }),
      catchError((err: unknown) => {
        console.error('Error in logout effect', err);
        return EMPTY;
      })
    )
  );

  readonly handleCallback = this.effect(($) => {
    return $.pipe(
      switchMap(() =>
        combineLatest([
          this.isAuthenticated$,
          this.currentUser$.pipe(startWith(null)),
        ])
      ),
      withLatestFrom(this.auth0Service.appState$),
      concatMap(([[isAuthenticated, currentUser], appState]) => {
        if (currentUser && currentUser.sub) {
          return forkJoin([
            of(isAuthenticated),
            of(appState),
            this.authService.getUserInfo(currentUser.sub),
          ]);
        }
        return forkJoin([of(isAuthenticated), of(appState), of(null)]);
      }),
      tap(([isAuthenticated, appState, employee]) => {
        let nextRoute = AppRoutes.PROFILE;

        if (employee) {
          localStorage.setItem(USER_DB_KEY, JSON.stringify(employee.data));
        }

        if (isAuthenticated) {
          if (
            !isEmpty(appState) &&
            !appState?.target?.includes(AppRoutes.AUTH)
          ) {
            nextRoute = appState.target;
          }
          void this.router.navigateByUrl('/' + nextRoute, {
            replaceUrl: true,
          });
        } else {
          void this.router.navigateByUrl(
            `/${AppRoutes.AUTH}/${AppRoutes.LOGIN}`,
            {
              replaceUrl: true,
            }
          );
        }
      }),
      catchError((err: unknown) => {
        console.error('Error in auth callback effect', err);
        this.handleCallback();
        return EMPTY;
      })
    );
  });
}
