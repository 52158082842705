import { RouterModule, Routes } from '@angular/router';

import { AppPermissions } from '@layouts/utils/app-permissions';
import { AppRoutes } from '@shared/utils/routes';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthorizeGuard } from '@shared/utils/guards/authorize.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: AppRoutes.AUTH,
    loadComponent: () =>
      import('./layouts/ui/empty/empty.component').then(
        (m) => m.EmptyComponent
      ),
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./layouts/ui/shell/shell.component').then(
        (m) => m.ShellComponent
      ),
    children: [
      {
        path: AppRoutes.PROFILE,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'My space',
          authorize: AppPermissions.PROFILE_READ,
        },
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: AppRoutes.REPORTS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Reports',
          authorize: AppPermissions.REPORTS_READ,
        },
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: AppRoutes.DASHBOARD,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Dashboard',
          authorize: AppPermissions.DASHBOARD_READ,
        },
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: AppRoutes.SETTINGS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Settings',
          authorize: AppPermissions.SETTINGS_READ,
        },
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: AppRoutes.EMPLOYEES,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Staff',
          authorize: AppPermissions.EMPLOYEE_READ,
        },
        loadChildren: () =>
          import('./employees/employees.module').then((m) => m.EmployeesModule),
      },
      {
        path: AppRoutes.LEAVES,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Absence requests',
          authorize: AppPermissions.ELEAVE_READ,
        },
        loadChildren: () =>
          import('./leaves/leaves.module').then((m) => m.LeavesModule),
      },
      {
        path: AppRoutes.MEMOS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Memos',
          authorize: AppPermissions.MEMOS_READ,
        },
        loadChildren: () =>
          import('./memos/memos.module').then((m) => m.MemosModule),
      },
      {
        path: AppRoutes.PAYMENTS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Payments',
          authorize: AppPermissions.PAYMENT_REQUEST_READ,
        },
        loadChildren: () =>
          import('./payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: AppRoutes.CLAIMS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Claims',
          authorize: AppPermissions.CLAIMS_READ,
        },
        loadChildren: () =>
          import('./claims/claims.module').then((m) => m.ClaimsModule),
      },
      {
        path: AppRoutes.VISITS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Visits & Appointments',
          authorize: AppPermissions.VISITORS_AND_APPOINTMENTS_READ,
        },
        loadChildren: () =>
          import('./visits/visits.module').then((m) => m.VisitsModule),
      },
      {
        path: AppRoutes.VISITORS,
        canActivate: [AuthGuard, AuthorizeGuard],
        title: 'Visitors',
        data: {
          breadcrumb: 'Visitors',
          authorize: AppPermissions.VISITORS_AND_APPOINTMENTS_READ,
        },
        loadComponent: () =>
          import(
            '@visits/ui/visitors-container/visitors-container.component'
          ).then((c) => c.VisitorsContainerComponent),
      },
      {
        path: AppRoutes.ASSETS,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Assets',
          authorize: AppPermissions.ASSET_REGISTER_READ,
        },
        loadChildren: () =>
          import('./assets/assets.module').then((m) => m.AssetsModule),
      },
      {
        path: AppRoutes.STORES,
        canActivate: [AuthGuard, AuthorizeGuard],
        data: {
          breadcrumb: 'Stores',
          authorize: AppPermissions.STOCK_REQUEST_READ,
        },
        loadChildren: () =>
          import('./stores/stores.module').then((m) => m.StoresModule),
      },
      {
        path: '',
        redirectTo: AppRoutes.PROFILE,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: AppRoutes.UNAUTHORIZED,
    loadComponent: () =>
      import('./layouts/ui/unauthorized-page/unauthorized-page.component').then(
        (m) => m.UnauthorizedPageComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./layouts/ui/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
