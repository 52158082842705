import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiSizeS } from '@taiga-ui/core';
import { TuiProgressModule } from '@taiga-ui/kit';

@Component({
  selector: 'app-base-progress-bar',
  standalone: true,
  imports: [CommonModule, TuiProgressModule],
  template: `
    <progress
      tuiProgressBar
      [max]="max"
      [size]="size"
      [color]="color"
      class="progress"></progress>
  `,
  styles: [
    `
      .progress {
        height: 0.3rem !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseProgressBarComponent {
  @Input() max = 100;

  @Input() value: number | undefined | null = null;

  @Input() size: TuiSizeS = 's';

  @Input() color = '';
}
