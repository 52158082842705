import { catchError, Observable, retry, throwError } from 'rxjs';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RequestStatusStore } from '@shared/data-access/services/request-status.store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly reqStatus = inject(RequestStatusStore);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(this.handleErrors());
  }

  // observable to tap into success http requests
  private handleSuccess = () => (source: Observable<HttpEvent<unknown>>) => {
    return source.pipe();
  };

  // observable to tap into failed http requests
  private handleErrors = () => (source: Observable<HttpEvent<unknown>>) => {
    return source.pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        this.reqStatus.updateHasError(true);
        this.reqStatus.updateSeverity('error');

        switch (error.status) {
          case 504:
            this.reqStatus.updateMessage(
              'Gateway Timeout: Check your internet connection and try again'
            );
            break;

          default:
            this.reqStatus.updateMessage(
              `${error?.error?.error ?? 'An error occurred'}: ${
                error?.error?.message ?? 'An error occurred'
              }`
            );
            break;
        }
        return throwError(() => error);
      })
    );
  };
}
