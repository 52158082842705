import { Lookup } from '@shared/data-access/models/lookup';

export type AuthState = {
  loading: boolean;
};

export const INITIAL_AUTH_STATE: AuthState = {
  loading: false,
};

export interface UserInfo {
  employeeId: number;
  fullName: string;
  email: string;
  picture: string;
  phoneNumber: null;
  department: Lookup;
  rank: Lookup;
  position: Lookup;
}
