import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { BaseResponse } from '@shared/utils/api-interfaces';
import { UserInfo } from '../models/auth.state';
import { catchError, EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly http = inject(HttpClient);

  getUserInfo(userId: string) {
    const encodedUserId = window.btoa(userId);
    return this.http
      .get<BaseResponse<UserInfo>>(
        `${environment.userBaseApi}/users/detail/${encodedUserId}`
      )
      .pipe(catchError((err) => EMPTY));
  }
}
