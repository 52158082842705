export const environment = {
  production: true,
  baseUrl: 'api',
  userBaseApi: 'user-service/v1',
  propertyBaseApi: 'property-service/v1',
  auth0Config: {
    domain: 'agghanahq.eu.auth0.com',
    clientId: 'G4gxDqdhPz0i3AXHHtrJgx03g2KgUTDx',
    redirectUri: `${window.location.origin}/auth/callback`,
    audience: 'https://agghana.com',
    scope: 'roles groups permissions',
  },
  release: '',
} as const;
