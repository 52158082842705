import { finalize, Observable } from 'rxjs';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BusyStore } from '@shared/data-access/services/busy.store';

@Injectable()
export class BusyInterceptor implements HttpInterceptor {
  private readonly busyStore = inject(BusyStore);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.busyStore.updateBusy(true);
    return next
      .handle(request)
      .pipe(finalize(() => this.busyStore.updateBusy(false)));
  }
}
