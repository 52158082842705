export const AppRoutes = {
  AUTH: 'auth',
  LOGIN: 'login',
  HOME: 'home',
  AUTH_CALLBACK: 'callback',
  DASHBOARD: 'dashboard',
  PROFILE: 'profile',
  REPORTS: 'reports',
  STATS: 'stats',
  SETTINGS: 'settings',
  EMPLOYEES: 'employees',
  LEAVES: 'leaves',
  MEMOS: 'memos',
  PAYMENTS: 'payments',
  CLAIMS: 'claims',
  VISITS: 'visits-and-appointments',
  VISITORS: 'visitors',
  ASSETS: 'assets',
  STORES: 'stores',
  MANAGE_USERS: 'manage-users',
  UNAUTHORIZED: 'unauthorized',
} as const;
