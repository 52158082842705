export const AppPermissions = {
  // Profile
  PROFILE_CREATE: 'create:profile',
  PROFILE_READ: 'read:profile',
  PROFILE_UPDATE: 'update:profile',
  PROFILE_DELETE: 'delete:profile',

  // Dashboard
  DASHBOARD_CREATE: 'create:dashboard',
  DASHBOARD_READ: 'read:dashboard',
  DASHBOARD_UPDATE: 'update:dashboard',
  DASHBOARD_DELETE: 'delete:dashboard',

  // Message portal
  MESSAGE_PORTAL_CREATE: 'create:message_portal',
  MESSAGE_PORTAL_READ: 'read:message_portal',
  MESSAGE_PORTAL_UPDATE: 'update:message_portal',
  MESSAGE_PORTAL_DELETE: 'delete:message_portal',

  // Reports
  REPORTS_CREATE: 'create:reports',
  REPORTS_READ: 'read:reports',
  REPORTS_UPDATE: 'update:reports',
  REPORTS_DELETE: 'delete:reports',

  // Settings
  SETTINGS_CREATE: 'create:settings',
  SETTINGS_READ: 'read:settings',
  SETTINGS_UPDATE: 'update:settings',
  SETTINGS_DELETE: 'delete:settings',

  // Users
  USERS_CREATE: 'create:users',
  USERS_READ: 'read:users',
  USERS_UPDATE: 'update:users',
  USERS_DELETE: 'delete:users',

  // Roles
  ROLES_CREATE: 'create:roles',
  ROLES_READ: 'read:roles',
  ROLES_UPDATE: 'update:roles',
  ROLES_DELETE: 'delete:roles',

  // Permissions
  PERMISSIONS_CREATE: 'create:permissions',
  PERMISSIONS_READ: 'read:permissions',
  PERMISSIONS_UPDATE: 'update:permissions',
  PERMISSIONS_DELETE: 'delete:permissions',

  // User_management
  USER_MANAGEMENT_CREATE: 'create:user_management',
  USER_MANAGEMENT_READ: 'read:user_management',
  USER_MANAGEMENT_UPDATE: 'update:user_management',
  USER_MANAGEMENT_DELETE: 'delete:user_management',

  // Visitors
  VISITORS_CREATE: 'create:visitors',
  VISITORS_READ: 'read:visitors',
  VISITORS_UPDATE: 'update:visitors',
  VISITORS_DELETE: 'delete:visitors',

  // Employee
  EMPLOYEE_CREATE: 'create:employee',
  EMPLOYEE_READ: 'read:employee',
  EMPLOYEE_UPDATE: 'update:employee',
  EMPLOYEE_DELETE: 'delete:employee',

  // Eleave
  ELEAVE_CREATE: 'create:e_leave',
  ELEAVE_READ: 'read:e_leave',
  ELEAVE_UPDATE: 'update:e_leave',
  ELEAVE_DELETE: 'delete:e_leave',
  ELEAVE_SUB_HEAD: 'approve:leave-subHead',
  ELEAVE_HEAD: 'approve:leave-head',

  // Claims
  CLAIMS_CREATE: 'create:claims',
  CLAIMS_READ: 'read:claims',
  CLAIMS_UPDATE: 'update:claims',
  CLAIMS_DELETE: 'delete:claims',

  // Memos
  MEMOS_CREATE: 'create:memos',
  MEMOS_READ: 'read:memos',
  MEMOS_UPDATE: 'update:memos',
  MEMOS_DELETE: 'delete:memos',

  // Payment_request
  PAYMENT_REQUEST_CREATE: 'create:payment_request',
  PAYMENT_REQUEST_READ: 'read:payment_request',
  PAYMENT_REQUEST_UPDATE: 'update:payment_request',
  PAYMENT_REQUEST_DELETE: 'delete:payment_request',
  PAYMENT_REQUEST_ASSIGN: 'assign:payment-request',
  PAYMENT_REQUEST_AUDIT: 'audit:payment-audit',
  PAYMENT_REQUEST_PROCESS: 'process:payment-request',
  PAYMENT_REQUEST_APPROVE: 'approve:payment-request ',

  // Visitors_and_appointments
  VISITORS_AND_APPOINTMENTS_CREATE: 'create:visitors_and_appointments',
  VISITORS_AND_APPOINTMENTS_READ: 'read:visitors_and_appointments',
  VISITORS_AND_APPOINTMENTS_UPDATE: 'update:visitors_and_appointments',
  VISITORS_AND_APPOINTMENTS_DELETE: 'delete:visitors_and_appointments',

  // Asset_register
  ASSET_REGISTER_CREATE: 'create:asset_register',
  ASSET_REGISTER_READ: 'read:asset_register',
  ASSET_REGISTER_UPDATE: 'update:asset_register',
  ASSET_REGISTER_DELETE: 'delete:asset_register',

  // Stores
  STOCK_REQUEST_CREATE: 'create:store_request',
  STOCK_REQUEST_READ: 'read:store_request',
  STOCK_REQUEST_UPDATE: 'update:store_request',
  STOCK_REQUEST_DELETE: 'delete:store_request',
  STOCK_REQUEST_APPROVE_DEPARTMENT_HEAD: 'approve:stores-department-head',
  STOCK_REQUEST_APPROVE_HEAD: 'approve:stores-head',
} as const;
