import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';

@Component({
  selector: 'app-tui-root',
  standalone: true,
  imports: [CommonModule, TuiRootModule, TuiDialogModule],
  template: `
    <tui-root>
      <ng-content></ng-content>
    </tui-root>
  `,
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent {}
