import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

type BusyState = {
  isBusy: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BusyStore extends ComponentStore<BusyState> {
  isBusy$ = this.select(({ isBusy }) => isBusy);

  constructor() {
    super({ isBusy: false });
  }

  readonly updateBusy = this.updater((state, isBusy: boolean) => ({
    ...state,
    isBusy,
  }));
}
