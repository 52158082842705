import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

type Severity = 'warning' | 'success' | 'error' | 'info';

type RequestStatusState = {
  hasError: boolean;
  message?: string;
  severity: Severity;
};

@Injectable({
  providedIn: 'root',
})
export class RequestStatusStore extends ComponentStore<RequestStatusState> {
  hasError$ = this.select(({ hasError }) => hasError);

  message$ = this.select(({ message }) => message);

  severity$ = this.select(({ severity }) => severity);

  constructor() {
    super({ hasError: false, severity: 'error' });
  }

  readonly updateHasError = this.updater((state, hasError: boolean) => ({
    ...state,
    hasError,
  }));

  readonly updateMessage = this.updater((state, message: string) => ({
    ...state,
    message,
  }));

  readonly updateSeverity = this.updater((state, severity: Severity) => ({
    ...state,
    severity,
  }));
}
