import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { TUI_SANITIZER } from '@taiga-ui/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { RootComponent } from '@shared/ui/root/root.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { Router, TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@shared/utils/page-title-strategy';
import { BaseProgressBarComponent } from '@shared/ui/base-progress-bar/base-progress-bar.component';
import { LetModule } from '@ngrx/component';
import { customInterceptors } from '@shared/utils/interceptors';
import { ComponentStore } from '@ngrx/component-store';
import { TuiPdfViewerModule } from '@taiga-ui/kit';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://8d08063a2a80488e8bb0a63c31f9b23a@o4504511725502464.ingest.sentry.io/4504511727271936',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  release: environment.release,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Auth0Module.forRoot({ ...environment.auth0Config }),
    RootComponent,
    BaseProgressBarComponent,
    LetModule,
    TuiPdfViewerModule,
  ],
  providers: [
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    ComponentStore,
    customInterceptors,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
