import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { AuthInterceptor } from './auth.interceptor';
import { BusyInterceptor } from './busy.interceptor';
import { ErrorInterceptor } from './error.interceptor';

export const customInterceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BusyInterceptor,
    multi: true,
  },
];
