import { Component, inject } from '@angular/core';
import { BusyStore } from '@shared/data-access/services/busy.store';

@Component({
  selector: 'app-root',
  template: ` <app-tui-root>
    <ng-container *ngrxLet="{ isBusy: isBusy$ } as vm">
      <app-base-progress-bar
        *ngIf="vm.isBusy"
        size="s"
        class="absolute inset-0 top-0 z-10"
      >
      </app-base-progress-bar>
    </ng-container>
    <router-outlet></router-outlet>
  </app-tui-root>`,
  styles: [],
})
export class AppComponent {
  private busyStore = inject(BusyStore);

  isBusy$ = this.busyStore.isBusy$;
}
